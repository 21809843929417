<template>
    <div class="reser">
        <div class="title" style="margin-top: 10px;">门诊预约列表</div>
        <div class="treat_main_list" v-if="reservationList.length > 0">
            <div class="one"  v-for="item in reservationList">
                <div class="info name_appointment">
                    <div class="left">{{ item.customername }} （ {{ item.apptypename }} ）</div>
                    <div class="right" v-if="item.id" @click=" $router.push({path:'/medicalDetail',query:{userid:$route.query.userid,customerid:$route.query.customerid,id:item.id,type:item.type}})">
                        <div class="detail_info" >
                            <span>门诊预约</span>
                            <i class="icon iconfont iconsanjiaoyou"></i>
                        </div>
                    </div>
                </div>
                <div class="doctor">
                    <div>{{ item.clinicname }}</div>
                    <div>{{item.doctor}}（序号：  {{item.appointmentnum}} ）</div>
                    <div style="color: #CF8C57;" >{{item.cancleflag == 1 ? '有效' : item.cancleflag == 2 ? '预占' : '无效'}}</div>
                </div>
                <div class="time">预约时间：{{ item.seetime || '--' }}</div>
            </div>
        </div>
        <div class="nodata"  v-else>暂无门诊预约数据</div>

        <div class="title">门诊预约历史</div>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            
            :offset="30"
            :immediate-check="false"
            @load="historyLists"
            >
            <div class="treat_main_list" v-if="historyList.length > 0">
                <div class="one"  v-for="item in historyList">
                    <div class="info name_appointment">
                        <div class="left">{{ item.customername }} （ {{ item.apptypename }} ）</div>
                        <div class="right" v-if="item.id" @click=" $router.push({path:'/medicalDetail',query:{userid:$route.query.userid,id:item.id,customerid:$route.query.customerid,type:item.type}})">
                            <div class="detail_info" >
                                <span>门诊预约</span>
                                <i class="icon iconfont iconsanjiaoyou"></i>
                            </div>
                        </div>
                    </div>
                    <div class="doctor">
                        <div>{{ item.clinicname }}</div>
                        <div>{{item.doctor}}（序号： {{item.appointmentnum}} ）</div>
                        <div style="color: #CF8C57;" >{{item.cancleflag == 1 ? '有效' : item.cancleflag == 2 ? '预占' : '无效'}}</div>
                    </div>
                    <div class="time">预约时间：{{ item.seetime || '--'  }}</div>
                </div>
            </div>
            <div class="nodata" v-else>暂无门诊预约数据</div>
        </van-list>
    </div>
</template>
<script>
import {wxtriagecurdayappointmentlistqry,wxtriageappointmentlistqry} from '@/api/medical'
import { mapGetters } from 'vuex'

export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
    data(){
        return{
            reservationList:[],
            historyList:[],
            finished:false,
            loading:false,
            page:0,
        }
    },
    created(){
        this.$store.dispatch('hideOrShowNav', false)
        this.intradayList()
        this.historyLists()
    },
    methods:{
        // 当天门诊预约记录
        intradayList(){
            let data = {
                username:this.username,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
            }
            wxtriagecurdayappointmentlistqry(data).then(response => {
                console.log(response)
                this.reservationList = response.response_body.list
            })
        },
        historyLists(){
            console.log(this.clinicid)
            this.page += 1;
            let data = {
                username:this.username,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
                qrypage:this.page,
            }
            wxtriageappointmentlistqry(data).then(response =>{
                
                this.loading = false
                if(response.response_body.count > 0){

                    this.historyList =[...this.historyList, ...response.response_body.list] || []
                    this.finished = false
                    if(this.page >= response.response_body.pages){
                        
                        this.finished = true
                    }
                }else{
                    this.finished = true
                    this.historyList = []
                }
         
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.reser{
    .title{
        font-size: 20px;
        font-weight: 700;
        padding: 0 10px;
        
    }
    .treat_main_list{
        margin-bottom: 0;
        padding-bottom: 0;
        background-color: #fff  !important;
        .info{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            padding: 15px 0;
        }
        .one{
           background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%) !important;
        }
        .doctor{
            display: flex;
            margin: 10px 0;
            justify-content: space-between;
        }
    }
    .nodata{
        text-align: center;
        margin: 20px;
        // background-color: #fafafa;
    }
}
</style>